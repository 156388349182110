import AlertIcon from '@icons/alert-icon.svg';
import BankIcon from '@icons/bank-icon.svg';
import CardIcon from '@icons/card-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import PendingIcon from '@icons/pending-icon.svg';
import { Banner, Button, Combobox, EBannerType, Loading, Modal, onMobileSetLarge, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import AddCreditCardModal from '@v2/components/Shared/AddCreditCard/AddCreditCardModal';
import { EStripeStatus } from '@v2/constants/EInvoiceStatus';
import useAccount from '@v2/hooks/useAccount.hook';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import useRolesHook from '@v2/hooks/useRolesHook';
import { tCommon, tNamespace } from '@v2/i18n';
import { EPaymentType, getPaymentMethods, PaymentMethod, processStripePayment, ProcessStripePaymentResponse, setupPayment, SupplierData, LegacyInvoiceData } from '@v2/network/CoreAPI';
import { useBillsStore } from '@v2/stores/BillsStore';
import { AccountData } from '@v2/types/AccountData';
import { ERoleName } from '@v2/types/OrgData';
import { logError } from '@v2/utils/logError';
import { AxiosResponse } from 'axios';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@icons/info-icon.svg';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import { isEditedExternally, isPaidExternally } from '@v2/utils/AccountingUtils';
import { useHasFeatureFlagEnabled } from '@v2/hooks/useHasFeatureFlagEnabled.hook';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { FETCH_EXTERNAL_INVOICES, getPaymentGLCodes } from '@v2/network/BushwhackAPI';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { centsToDollars } from '@v2/utils/CurrencyUtils';
import { getSupplier } from '@v2/network/LegacyAPI';
import { EApPlatform, getAccountingConfiguration } from '@notch-ordering/shared-logic';
import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import { PayBillModalInvoice } from './PayBillModalInvoice';
import Utils from '@/utils';

type Props = {
    isOpen: boolean,
    close: () => void,
    onClose: () => void,
    bills: InvoiceData[],
    supplier: SupplierData,
};

const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const FETCH_ACCOUNTING_INTEGRATIONS = 'FETCH_ACCOUNTING_INTEGRATIONS';

export const PayBillModal : React.FC<Props> = ({
    isOpen,
    close,
    onClose,
    bills,
    supplier
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices.PayModal' });
    const { hasRole } = useRolesHook();
    const { buyer } = useBuyerHook();
    const [selectedPayment, setSelectedPayment] = useState<PaymentMethod>(null);
    const [errorMessage, setErrorMessage] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaying, setIsPaying] = useState<boolean>(false);
    const [paymentGLCode, setPaymentGLCode] = useState<string>('');
    const [isSetupCreditSuccess, setIsSetupCreditSuccess] = useState<boolean>(false);
    const [isSetupDebitSuccess, setIsSetupDebitSuccess] = useState<boolean>(false);
    const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const { setStripeStatus } = useBillsStore();
    const { email }: AccountData = useAccount();
    const integrationsEnabled = useHasFeatureFlagEnabled(EFirebaseParams.INTEGRATIONS_ENABLED);
    const buyerIntegrationEnabled = useBuyerHasFeatureFlagEnabled(EFirebaseParams.ACCOUNTING_BUYERS);

    const accountingIntegration = useQuery([FETCH_ACCOUNTING_INTEGRATIONS, buyer.urlsafe], () => getAccountingConfiguration(buyer.urlsafe), { enabled: !!buyer.urlsafe, cacheTime: 0, retry: 1 });

    const billingDetails = {
        email: buyer.email || email,
        name: buyer.name,
    };

    const glCodeEnabled = !!accountingIntegration?.data;

    const platform = accountingIntegration.data?.isEnabled ? accountingIntegration?.data?.data?.platform : undefined;
    const accessToken = accountingIntegration?.data?.data?.accessToken;
    const { data: glCodesResponse } = useQuery(
        ['PAYMENT GL CODES', buyer.urlsafe],
        () => getPaymentGLCodes(platform, buyer.urlsafe, accessToken),
        {
            enabled: !!accessToken && !!platform,
        }
    );

    const glCodeOptions = glCodesResponse?.map((glCode) => ({
        label: glCode.name,
        value: glCode.id
    })) ?? [];

    // Total of all bills being paid
    const totalAmount = useMemo(() => {
        let amount = 0;
        for (let i = 0; i < bills.length; i++) {
            const bill = bills[i];
            amount += bill.invoiceTotal;
        }

        return centsToDollars(amount);
    }, [bills]);
    const { data: paymentMethods, isLoading: isFetchingPaymentMethods, refetch, error: paymentMethodError } = useQuery<PaymentMethod[]>(
        [FETCH_PAYMENT_METHODS, buyer?.urlsafe, supplier?.urlsafe],
        () => {
            const invoices: LegacyInvoiceData[] = bills.map((b) => ({ invoice_number: b.invoiceNumber, amount: b.invoiceTotal * 0.01 }));
            return getPaymentMethods(buyer?.urlsafe, supplier?.urlsafe, invoices);
        },
        {
            enabled: isOpen && !!buyer?.urlsafe && !!supplier?.urlsafe,
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            onError: () => {
                setErrorMessage(t('supplierNotSetupLong', { name: supplier?.name }));
            },
            onSuccess: () => {
                setSelectedPayment(null);
                // After setting up a payment, keep refetching until we get a payment method
                if (isSetupCreditSuccess && !paymentMethods.find((p) => p.type === EPaymentType.CARD)) {
                    refetch();
                }

                if (isSetupDebitSuccess && !paymentMethods.find((p) => p.type === EPaymentType.PRE_AUTHORIZED_DEBIT)) {
                    refetch();
                }
            }
        },
    );
    const cardPayment: PaymentMethod = useMemo<PaymentMethod>((): PaymentMethod => paymentMethods?.find((p) => p.type === EPaymentType.CARD), [paymentMethods]);
    const debitPayment: PaymentMethod = useMemo<PaymentMethod>((): PaymentMethod => paymentMethods?.find((p) => p.type === EPaymentType.PRE_AUTHORIZED_DEBIT), [paymentMethods]);

    useEffect(() => {
        setSelectedPayment(null);
        setErrorMessage(null);
        setIsSetupDebitSuccess(false);
        setIsSetupCreditSuccess(false);

        if (isOpen) {
            const accountingEnabled: boolean = accountingIntegration.isFetched && accountingIntegration.data?.isEnabled;
            if (integrationsEnabled && buyerIntegrationEnabled && accountingEnabled) {
                for (let i = 0; i < bills.length; ++i) {
                    const bill: InvoiceData = bills[0];
                    const { orderSyncDetails } = bill;
                    let errorType: string | null = null;
                    const accountingType: string = getAccountingConfigurationType(accountingIntegration?.data);

                    if (orderSyncDetails?.error) {
                        errorType = 'errorAccountingSync';
                    } else if (isPaidExternally(bill, accountingType as EApPlatform)) {
                        // note: We are not supporting partial payments currently
                        // if payments via an acccounting integration are found, we set the status as Remote Paid
                        errorType = 'errorAccountingRemotePaid';
                    } else if (isEditedExternally(bill)) {
                        errorType = 'errorAccountingRemoteEdit';
                    }

                    if (errorType !== null) {
                        let accounting: string;

                        switch (accountingType) {
                            case EApPlatform.QBO:
                                accounting = t('accountingTypeQBO');
                                break;
                            case EApPlatform.NETSUITE:
                                accounting = t('accountingTypeNetsuite');
                                break;
                            case EApPlatform.XERO:
                                accounting = t('accountingTypeXero');
                                break;
                            case EApPlatform.MD365:
                                accounting = t('accountingTypeMD365');
                                break;
                            case EApPlatform.QBD:
                                accounting = t('accountingTypeQBD');
                                break;
                            default:
                                accounting = t('accountingDefaultName');
                        }

                        setErrorMessage(t(errorType, { accounting }));

                        // Just show a single error
                        break;
                    }
                }
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (!bills || bills.length === 0) {
            close();
        }
    }, [bills]);

    async function payInvoices(): Promise<void> {
        if (!hasRole([ERoleName.OWNER]) || isPaying) {
            return;
        }

        setIsPaying(true);

        try {
            const supplierData = await getSupplier(supplier.urlsafe);
            const { results }: ProcessStripePaymentResponse = await processStripePayment({
                buyerUrlsafeKey: buyer.urlsafe,
                supplierUrlsafeKey: supplier.urlsafe,
                invoices: bills.map((invoice) => ({
                    amount: centsToDollars(invoice.invoiceTotal),
                    invoice_number: invoice.invoiceNumber,
                    gl_code_id: paymentGLCode,
                    id: invoice.orderUrlsafeKey ? invoice.orderUrlsafeKey : invoice.id,
                    items: invoice.lineItems.map((item) => ({
                        description: item.description,
                        amount: item.total,
                        quantity: item.quantity,
                        price: item.pricePerUnit
                    })),
                    vendor_name: supplierData.name,
                })),
                stripeCustomerID: selectedPayment.stripeCustomerId,
                stripeSupplierAccountID: selectedPayment.stripeSupplierAccountId,
                stripePaymentMethodID: selectedPayment.id
            });

            const successes = results.filter((inv) => inv.success);
            const fails = results.filter((inv) => !inv.success);

            if (successes.length > 0) {
                toast.show({
                    icon: <CheckIcon />,
                    message: t('paySuccess', { count: successes.length }),
                });

                // Immediately set stripe statuses to open, which will display as 'processing'
                successes.forEach((bill) => {
                    setStripeStatus(bill.invoiceId, EStripeStatus.OPEN);
                });
            }

            if (fails.length > 0) {
                toast.show({
                    icon: <AlertIcon className="text-red-300"/>,
                    message: t('payFail', { count: fails.length })
                });
                fails.forEach((bill) => {
                    setStripeStatus(bill.invoiceId, EStripeStatus.FAILED);
                });
            }
            queryClient.invalidateQueries([FETCH_EXTERNAL_INVOICES, buyer.urlsafe]);
        } catch (error) {
            switch (error.status) {
                case 404:
                case 400:
                    toast.show({
                        icon: <AlertIcon className="text-red-300"/>,
                        message: t('supplierNotSetupShort', { name: supplier.name })
                    });
                    break;
                default:
                    logError('Could not process stripe payment', error, true);
                    break;
            }
            bills.forEach((bill) => {
                setStripeStatus(bill.orderUrlsafeKey, EStripeStatus.FAILED);
            });
            queryClient.invalidateQueries([FETCH_EXTERNAL_INVOICES, buyer.urlsafe]);
        }

        setIsPaying(false);
        close();
        onClose();
    }

    /**
     * Uses stripe.js SDK to show their UI for collecting pre-authorized debit information
     */
    async function setupStripePAD(): Promise<void> {
        if (!hasRole([ERoleName.OWNER, ERoleName.MANAGER])) {
            return;
        }

        setErrorMessage(null);
        setIsLoading(true);
        const invoices = bills.map((b) => ({ invoice_number: b.invoiceNumber, amount: centsToDollars(b.invoiceTotal) }));
        const stripePaymentResponse = await setupPayment({
            buyerData: { ...billingDetails, id: buyer.id },
            buyerUrlsafeKey: buyer.urlsafe,
            supplierUrlsafeKey: supplier.urlsafe,
            invoices
        }).catch((response: AxiosResponse) => {
            setIsLoading(false);

            // Check if it is an error from the Stripe API
            if (response?.data?.error?.statusCode) {
                setErrorMessage(`${tCommon('somethingWentWrong')} ${tCommon('tryAgain')}`);
                logError('Stripe error on approve & pay bill.', response.data.error, true);
                setIsLoading(false);
                return;
            }

            // Otherwise handle an error from Core API
            switch (response.status) {
                // When PAD is already found, refetch their paymentMethods
                case 400:
                    refetch();
                    break;
                case 404:
                    setErrorMessage(t('supplierNotSetupLong', { name: supplier.name }));
                    break;
                default:
                    setErrorMessage(response.data?.error);
                    logError(`Error ${response.status} on setupPayment`, response.data, true);
                    break;
            }
        });

        if (stripePaymentResponse) {
            // Open stripe UI to enter PAD information
            // Initialize stripe
            const stripe: Stripe | void = await loadStripe(process.env.STRIPE_API_KEY)
                .catch((reason) => {
                    logError('Failed to initialize Stripe.js', reason, true);
                });

            if (!stripe) {
                setErrorMessage(`${tCommon('somethingWentWrong')} ${tCommon('tryAgain')}`);
                logError('Failed to initialize Stripe.js', new Error('Unknown'), true);
                setIsLoading(false);
                return;
            }

            const { setupIntent, error } = await stripe.confirmAcssDebitSetup(
                stripePaymentResponse.stripeClientSecret,
                {
                    payment_method: { billing_details: billingDetails },
                }
            );

            if (error) {
            // Inform the customer that there was an error.
                setErrorMessage(error.message);
            } else {
                switch (setupIntent.status) {
                    case 'succeeded':
                        toast.show({
                            icon: <CheckIcon />,
                            message: t('padInfoSuccess')
                        });
                        setIsSetupDebitSuccess(true);
                        break;
                    case 'processing':
                    case 'requires_action':
                    case 'requires_confirmation':
                    case 'requires_payment_method':
                        toast.show({
                            icon: <PendingIcon />,
                            message: t('padInfoPendingVerification')
                        });
                        break;
                    case 'canceled':
                    default:
                        toast.show({
                            message: t('transactionCanceled')
                        });
                        break;
                }
            }

            refetch();
            setIsLoading(false);
        }
    }

    return <>
        <Modal
            isOpen={isOpen}
            close={close}
            title={t('payBill', { count: bills?.length })}
            description={supplier?.name}
            modalSize={onMobileSetLarge('MEDIUM')}
            onClose={onClose}>
            <div className="lg:mx-8 mx-4 mb-6">
                {/* Bills */}
                <div className="flex flex-col gap-y-3">
                    {bills.map((bill) => (
                        <PayBillModalInvoice
                            key={bill.id}
                            id={bill.id}
                            invoiceNumber={bill.invoiceNumber}
                            total={centsToDollars(bill.invoiceTotal)}/>
                    ))}
                </div>
            </div>
            <Separator />
            {/* Totals */}
            <div className="mb-6 mt-6 lg:mx-8 mx-4 flex flex-col gap-y-3">
                {/* Until credits are implements subtotal and total are always equal */}
                <Typography as="div" className="flex justify-between text-gray-600">
                    <div>{t('subtotal')}</div>
                    <div>{Utils.formatAsCurrency(totalAmount)}</div>
                </Typography>
                <Typography as="div" weight="font-medium" className="flex justify-between">
                    <div>{t('total')}</div>
                    <div>{Utils.formatAsCurrency(totalAmount)}</div>
                </Typography>
            </div>
            <Separator variant="large" />
            {(!!platform && glCodeOptions.length > 0) && <div className="flex flex-col px-8 py-6">
                <Typography className="mb-3" weight="font-semibold">GL Code</Typography>
                <Combobox
                    placeholder="Select"
                    value={paymentGLCode}
                    onChange={(value): void => setPaymentGLCode(value.value)}
                    options={glCodeOptions}/>
            </div>}
            {(!!platform && glCodeOptions.length > 0) && <Separator variant="large" className="mb-6" />}
            {/* Payment Methods */}
            <div className="lg:mx-8 mx-4 mt-6 mb-[18px]">
                <Typography as="div" weight="font-semibold" className="mb-3">{t('paymentMethod')}</Typography>
                <Loading isDark hidden={!isLoading && !isFetchingPaymentMethods}/>
                <div hidden={isLoading || isFetchingPaymentMethods} className="flex flex-col gap-y-3">
                    {/* Card */}
                    <Button className={cx('text-left flex gap-2 items-center px-4 py-3', { 'text-gray-600': !cardPayment })}
                        variant="TERTIARY_OUTLINED"
                        size="MEDIUM"
                        onClick={(): void => {
                            if (cardPayment) {
                                setSelectedPayment(cardPayment);
                            } else {
                                setIsCreditCardModalOpen(true);
                            }
                        }}
                        selected={selectedPayment === cardPayment}
                        disabled={!!paymentMethodError}>
                        <CardIcon className="h-4 w-4"/> {t('card')} {cardPayment && `•••• ${cardPayment?.last4}`}
                    </Button>
                    {/* Debit */}
                    <Button className={cx('text-left flex gap-2 items-center px-4 py-3', { 'text-gray-600': !debitPayment })}
                        variant="TERTIARY_OUTLINED"
                        size="MEDIUM"
                        onClick={(): void => {
                            if (debitPayment) {
                                setSelectedPayment(debitPayment);
                            } else {
                                setupStripePAD();
                            }
                        }}
                        selected={selectedPayment === debitPayment}
                        disabled={!!paymentMethodError}>
                        <BankIcon className="h-4 w-4"/> {t('preAuthorizedDebit')} {debitPayment && `•••• ${debitPayment?.last4}`}
                    </Button>
                </div>
            </div>
            <div className="lg:mx-8 mx-4">
                { errorMessage
                    && <div className="w-full mb-4">
                        <Banner alertType={EBannerType.ERROR}
                            body={<Typography as="div" className="text-gray-600">
                                {errorMessage}
                            </Typography>}
                            icon={<InfoIcon className="w-5 h-5 text-red-300"/>}
                            isDismissable={false}/>
                    </div>
                }
                <Button
                    variant="SECONDARY"
                    size="MEDIUM"
                    className="w-full"
                    onClick={payInvoices}
                    disabled={errorMessage != null || isFetchingPaymentMethods || isLoading || !selectedPayment || (!paymentGLCode && glCodeEnabled)}
                    loading={isPaying}>
                    {t('payAmount', { amount: Utils.formatAsCurrency(totalAmount) })}
                </Button>
            </div>
        </Modal>
        <AddCreditCardModal
            isOpen={isCreditCardModalOpen}
            setIsOpen={setIsCreditCardModalOpen}
            onClose={refetch}
            onSuccess={(): void => setIsSetupCreditSuccess(true)}
            supplier={supplier}
            bills={bills}/>
    </>;
};
